<template>
    <div
        class="media-table-breadcrumbs text-sm d-flex align-center"
    >
        <v-tooltip bottom>
            <template #activator="{attr, on}">
                <v-btn
                    :disabled="!combackOn"
                    color="primary"
                    icon
                    v-bind="attr"
                    v-on="{...combackOn, ...on}"
                    class="v-btn--comback"
                >
                    <v-icon>mdi-folder-home</v-icon>
                </v-btn>
            </template>
            <span>{{ $trans('Home directory') }}</span>
        </v-tooltip>
        <v-btn
            v-if="prevOn"
            color="primary"
            icon
            v-on="prevOn"
            class="v-btn--comback"
        >
            <w-icon
                value="COM_BACK"
            />
        </v-btn>
        <div
            v-if="folderTitle"
            class="text-truncate font-weight-bold px-3 flex-fill"
            style="max-width: 100%"
        >
            {{ folderTitle }}
        </div>

<!--        <div class="text-truncate">-->
<!--            <template-->
<!--                v-for="(breadcrumb, index) in breadcrumbs"-->
<!--            >-->
<!--            <span-->
<!--                v-if="!breadcrumb.first && !isMobile"-->
<!--                :key="`separator-${index}`"-->
<!--                v-text="' / '"-->
<!--            />-->
<!--                <v-btn-->
<!--                    :key="breadcrumb.item.uuid"-->
<!--                    v-bind="breadcrumb.bindings"-->
<!--                    v-on="breadcrumb.von"-->
<!--                >-->
<!--                    <span-->
<!--                        class="text-truncate"-->
<!--                    >-->
<!--                        {{ breadcrumb.item.title }}-->
<!--                    </span>-->
<!--                </v-btn>-->
<!--            </template>-->
<!--        </div>-->
    </div>
</template>

<script>
import mobileBreakpointChecker from '@/mixins/mobileBreakpointChecker'
import isMediaViewSearchFlagMixin from '@apps/media/mixins/isMediaViewSearchFlagMixin'
import isMediaViewTrashedFlagMixin from '@apps/media/mixins/isMediaViewTrashedFlagMixin'

export default {
    name: 'MediaTableBreadcrumbs',
    mixins: [
        mobileBreakpointChecker,
        isMediaViewSearchFlagMixin,
        isMediaViewTrashedFlagMixin
    ],
    props: {
        parent: {
            type: Object,
            default: null
        },
        items: {
            type: Array,
            default: function () {
                return []
            }
        },
        sharedUuid: {
            type: String,
            default: null
        }
    },
    computed: {
        isMediaViewShared() {
            if(!this.parent) {
                return false
            }

            return !!this.parent.shared
                .find(o => o.shared_uuid === this.sharedUuid)
        },
        folderTitle() {
            if(!this.parent) {
                return null
            }

            return this.parent.title
        },
        prevOn() {
            const parent = this.parent

            if(!parent) {
                return false
            }

            if(parent.shared && !!parent.shared.find(o => o.shared_uuid === this.sharedUuid)) {
                return false
            }

            if(parent && parent.parent_uuid === null) {
                return false
            }

            return {
                click: () => {
                    this.$emit('click', {
                        parent: this.parent.parent_uuid
                    })
                }
            }
        },
        combackOn() {
            if(this.isMediaViewSearch || this.isMediaViewTrashed) {
                return {
                    click: () => {
                        this.$emit('comback', {
                            parent: null
                        })
                    }
                }
            }

            if(!this.parent) {
                return false
            }

            return {
                click: () => {
                    this.$emit('comback', {
                        parent: null
                    })
                }
            }
        },
        breadcrumbs() {
            const items = []

            this.items.forEach((item, i) => {
                const isLast = i === (this.items.length - 1)

                const breadcrumb = {
                    bindings: {
                        text: true,
                        minWidth: 'auto',
                        exact: true,
                        class: ['px-1']
                    },
                    first: i === 0,
                    item,
                    von: {}
                }

                if(!isLast) {
                    breadcrumb.von = {
                        click: () => {
                            this.$emit('click', { parent: item.uuid })
                        }
                    }
                } else {
                    breadcrumb.bindings.class.push('v-btn--active')
                }

                items.push(breadcrumb)
            })

            return this.isMobile && items.length > 1
                ? [items[items.length - 1]]
                : items
        }
    },
    data() {
        return {}
    },
    methods: {
        onClick(payload, isLast) {
            if(isLast) {
                return
            }

            this.$emit('click', payload)
        }
    }
}
</script>

<style lang=scss>
.media-table-breadcrumbs {
    .v-btn {
        text-transform: none !important;
        font-weight: bold;
        opacity: .8;

        &--comback {
            opacity: 1;
        }

        &:hover, &:active, &:focus {
            color: var(--v-anchor-base);
        }

        &--active {
            pointer-events: none;
            opacity: 1;

            &:before {
                background-color: transparent !important;
            }
        }
    }
}
</style>
